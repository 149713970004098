import React from "react";
import * as styles from "../styles/speakers.module.css";

function Speakers(props) {
  return (
    <span className="speakers">
      {props.speakersList.map((speaker) => (
        <span className={`speaker ${styles.speaker}`} key={speaker._id}>
          {speaker.personTitle} {speaker.forename} {speaker.surname}
        </span>
      ))}
    </span>
  );
}

export default Speakers;
