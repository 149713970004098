import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import BlockContent from "../components/block-content";
import Layout from "../components/layout";
import Speakers from "../components/speakers";
import "../styles/news.css";

export default function GetPastEvents() {
  const data = useStaticQuery(graphql`
    query GetAllEvents {
      allSanityGroupEvent(sort: { fields: date, order: DESC }) {
        nodes {
          _id
          title
          date(formatString: "D MMMM YYYY")
          speakers {
            _id
            personTitle
            forename
            surname
          }
          href
          _rawBody
        }
      }
    }
  `);

  const events = data.allSanityGroupEvent.nodes;
  // find day after event
  events.forEach(function (event) {
    const eventDate = new Date(event.date);
    const eventOver = new Date(eventDate);
    eventOver.setDate(eventDate.getDate() + 1);
    event.nextDay = Number(eventOver);
  });

  return (
    <Layout title="Past events" description="Past events from The Design Group">
      {events
        // display only events in the past
        .filter((event) => new Date(event.nextDay) < new Date())
        .map((event) => (
          <article className="event-item" key={event._id}>
            <h3>{event.title}</h3>
            <time>{event.date}</time>
            <Speakers speakersList={event.speakers} />
            <BlockContent blocks={event._rawBody || []} />
            <Link to={event.href}>More details</Link>
          </article>
        ))}
    </Layout>
  );
}
